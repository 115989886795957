.proj-imgbox {
  height: 90%;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;

  img {
    height: 100%;
  }

  &::before {
    content: "";
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }
  &:hover::before {
    height: 100%;
  }

  .proj-text {
    color: white;
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
    h4 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0.8px;
      line-height: 1.1em;
    }
    span {
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0.8px;
    }
  }
  &:hover .proj-text {
    top: 50%;
    opacity: 1;
  }
}
