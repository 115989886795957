.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
  h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
  p {
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }
  .nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    .nav-item {
      width: 33.33333%;
      &:nth-of-type(1) {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 55px 0px 0px 55px;
      }
      &:nth-of-type(2) {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
      &:nth-of-type(3) {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0 55px 55px 0;
      }
      .nav-link {
        background-color: transparent;
        border-radius: 0;
        padding: 17px 0;
        color: #fff;
        width: 100%;
        font-size: 17px;
        letter-spacing: 0.8px;
        font-weight: 500;
        position: relative;
        transition: 0.3s ease-in-out;
        text-align: center;
        z-index: 0;
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          background: linear-gradient(
            90.21deg,
            #aa367c -5.91%,
            #4a2fbd 111.58%
          );
          top: 0;
          left: 0;
          z-index: -2;
          transition: 0.3s ease-in-out;
        }
        &.active {
          border: 1px solid rgba(255, 255, 255, 1);
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  .background-img-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }
}
