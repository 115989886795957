.footer {
  padding: 20px 0 30px 0;
  background-image: url("../../assets/img/footer-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 26%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #b8b8b8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
}
