.banner {
  margin-top: 0;
  padding: 160px 0 100px 0;
  background-image: url("../../assets/img/banner-bg.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(
      90.21deg,
      rgba(170, 54, 124, 0.5) -5.91%,
      rgba(74, 47, 189, 0.5) 111.58%
    );
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }
  p {
    color: #b8b8b8;
    font-size: 22px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }
  button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    svg {
      font-size: 25px;
      margin-left: 10px;
      transition: 0.3s ease-in-out;
      line-height: 1;
    }
    &:hover svg {
      margin-left: 25px;
    }
  }
  img {
    animation: updown 12s linear infinite;
  }
}

@keyframes updown {
  0% {
    transform: translate(0px, 20px) rotate(0deg);
  }
  10% {
    transform: translate(0px, -20px) rotate(0deg);
  }
  20% {
    transform: translate(0px, 20px) rotate(0deg);
  }
  30% {
    transform: translate(-20px, -20px) rotate(-40deg);
  }
  40% {
    transform: translate(20px, 20px) rotate(+20deg);
  }
  50% {
    transform: translate(-20px, -20px) rotate(-20deg);
  }
  60% {
    transform: translate(20px, 20px) rotate(+20deg);
  }
  70% {
    transform: translate(-20px, -20px) rotate(-20deg);
  }
  80% {
    transform: translate(20px, 20px) rotate(+20deg);
  }
  90% {
    transform: translate(-20px, -20px) rotate(-20deg);
  }
  100% {
    transform: translate(0px, 20px) rotate(0deg);
  }
}
.wrap {
  border-right: 0.08em solid #666;
}
