.skill {
  padding: 0 0 50px 0;
  position: relative;
  .skill-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
  }
  h2 {
    font-size: 45px;
    font-weight: 700;
  }
  h5 {
    font-size: 25px;
    font-weight: 700;
  }
  p {
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
  }
  .skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
    li {
      display: flex;
      align-items: center;
    }
    .item {
      background-color: #151515;
      img {
        width: 50%;
        margin: 0 auto 15px auto;
      }
    }
  }

  .background-img-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
  }
}
